<template>
  <div class="login-main">
    <b-row>
      <b-col md="5" lg="4" sm="12">
        <div class="modal-login">
          <div class="container-login-img"><LogoEmpresa /></div>
          <transition mode="out-in" name="fade-left" appear>
            <!-- Cadastre-se grátis -->
            <div class="container-senha" key="senha">
              <h3>Comece hoje a monitorar as suas vendas com a vDash</h3>
              <p>Finalize seu cadastro para saber em tempo real as principais informações de vendas do seu e-commerce.</p>
              <ul>
                <li class="done">Insira seu e-mail de acesso</li>
                <li>Acesse o link no seu e-mail</li>
                <li>Finalize seu cadastro e pronto!</li>
              </ul>
            </div>
          </transition>
        </div>
      </b-col>
      <b-col md="7" lg="8" sm="12" class="colored_side">
        <h2>Insira o seu e-mail de acesso</h2>
        <div class="modal-form">
          <inputValidate :erro="emailSubscribe" :color="!isMobile ? 'white' : null">
            <input
              type="text"
              placeholder="Seu e-mail corporativo"
              v-model.trim="email"
              @blur="$v.email.$touch()"
              @keyup.enter="enterParaEnviar"
            />
          </inputValidate>
          <inputValidate :erro="lgpdSubscribe" :color="!isMobile ? 'white' : null">
            <div class="modal-form__checkbox">
              <input type="checkbox" v-model.trim="lgpd" @change="$v.lgpd.$touch()" />
              <label for="lgpd">
                Aceito os
                <a href="https://vdash.com.br/termos-de-uso" target="_blank">Termos de uso</a>
                e
                <a href="https://vdash.com.br/politica-de-privacidade" target="_blank">Privacidade</a>
                .
              </label>
            </div>
          </inputValidate>
          <b-button block variant="success" class="btn-enviar-convite" @click="enviarConvite">
            Enviar convite
          </b-button>
          <div class="login-links">
            <a class="senha-voltar" @click.prevent="login">Já tenho uma conta</a>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { emailSubscribe, lgpdSubscribe } from "@/plugins/vuelidate/login";
import * as api from "@/api/login";
import inputValidate from "@/components/formulario/InputValidate.vue";
import LogoEmpresa from "@/components/logos/LogoPositivo.vue";
export default {
  name: "login",
  components: {
    inputValidate,
    LogoEmpresa,
  },
  data() {
    return {
      email: "",
      lgpd: false,
      isMobile: window.innerWidth < 768,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    lgpd: {
      required,
    },
  },
  computed: {
    emailSubscribe,
    lgpdSubscribe,
  },
  methods: {
    login() {
      this.$router.push("/login");
    },
    enviarConvite() {
      if (this.$v.email.$invalid || this.lgpd === false) {
        this.$v.$touch();
      } else {
        api.preCadastro(this.email, this.$route.query);
      }
    },
    enterParaEnviar() {
      this.enviarConvite();
    },
  },
};
</script>

<style lang="scss" scoped>
.login-main {
  min-height: 100vh;
  padding: 0;
  background: $branco;
}
.colored_side {
  background: $branco;
  min-height: 100%;
  @include for-tablet-landscape-down {
    @include gradientRight;
  }
}
.modal-login {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
  padding: 18px;
  padding-bottom: 0;
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
  @include for-tablet-landscape-down {
    padding: 0 50px;
    min-height: 100vh;
  }
}
.login-links {
  padding: 24px;
  text-align: center;
  width: 100%;
  a {
    display: block;
    cursor: pointer;
    text-decoration: underline;
    color: $textos-login;
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 400;
    @include for-tablet-landscape-down {
      color: white;
    }
  }
}
.container-login-img {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 40%;
  img {
    width: 100%;
    max-width: 450px;
    height: auto;
    max-height: 250px;
    pointer-events: none;
    @media (max-width: 700px) {
      max-height: 150px;
    }
  }
}
.container-login,
.container-senha {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 250px;
  > div {
    width: 100%;
  }
  ul {
    color: $dark;
    font-size: 14px;
    margin-bottom: 15px;
    padding-left: 25px;
    @include for-tablet-landscape-down {
      margin-bottom: 30px;
    }
    li {
      position: relative;
      margin: 10px 0;

      &.done {
        &:before,
        &:after {
          background: $red;
        }
      }

      &:before {
        background: rgba(0, 0, 0, 0.1);
        content: "";
        height: 3px;
        left: -20px;
        position: absolute;
        top: 10px;
        transform: rotate(45deg);
        width: 5px;
      }

      &:after {
        background: rgba(0, 0, 0, 0.1);
        content: "";
        height: 3px;
        left: -18px;
        position: absolute;
        top: 9px;
        transform: rotate(-45deg);
        width: 13px;
      }
    }
  }
  h3 {
    font-size: 17px;
    font-weight: 500;
    width: 100%;
    margin: 20px 0 10px;
    color: $textos-login;
  }
  p {
    font-size: 14px;
    width: 100%;
    margin: 0px 0 15px;
    color: $textos-login;
  }
}
.btn-enviar-convite {
  font-weight: 500;
  padding: 10px;
}
.login-copy {
  width: 100%;
  text-align: center;
  p {
    font-size: 18px;
    color: $textos-login;
  }
}
.colored_side {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px 50px;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  @include for-tablet-landscape-down {
    max-width: inherit;
    padding: 100px;
  }
  h2 {
    color: $red;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    width: 100%;
    text-align: left;
    @include for-tablet-landscape-down {
      width: auto;
      font-size: 34px;
      font-weight: 500;
      margin-bottom: 30px;
      color: #fff;
    }
  }
  .modal-form {
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    width: 100%;
    &__checkbox {
      align-items: center;
      color: $textos-login;
      display: flex;
      justify-content: flex-start;
      padding-bottom: 40px;
      @include for-tablet-landscape-down {
        color: white;
      }
      input[type="checkbox"] {
        margin: 0 6px 0 0;
        height: 15px;
        width: 15px;
      }
      label {
        font-weight: 500;
        @include for-phone-only {
          font-size: 12px;
        }
        a {
          color: $textos-login;
          text-decoration: underline;
          @include for-tablet-landscape-down {
            color: #ffffff;
          }
        }
      }
    }
    .input-erro-container {
      color: white !important;
      width: 100%;
      input[type="text"] {
        color: $gray;
        width: 100%;
        @include for-tablet-landscape-down {
          color: #fff;
        }
        &::placeholder {
          color: $gray;
          @include for-tablet-landscape-down {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
